// import React, { useContext, useEffect } from 'react'
// import { Route, Routes } from 'react-router-dom'
// import Layout from '../components/common/Layout'
// import UserProfile from '../components/userManagement/UserProfile'
// import UserManagement from '../components/userManagement/UserManagement'
// import FleetManagement from '../components/fleetManagement/FleetManagement'
// import AddAircraft from '../components/fleetManagement/Aircraft/AddAircraft'
// import SingleAircraft from '../components/fleetManagement/Aircraft/SingleAircraft'
// import AirportManagement from '../components/airportManagement/AirportManagement'
// import BookingManagement from '../components/bookingManagement/BookingManagement'
// import CouponsManagement from '../components/couponsManagement/CouponsManagement'
// import CouponDetails from '../components/couponsManagement/CouponDetails'
// import ManageBMJRewardPoints from '../components/manageBmjRewardPoints/ManageBMJRewardPoints'
// import BookingStats from '../components/bookingStats/BookingStats'
// import OfferedFlightManagement from '../components/offeredFlightManagement/OfferedFlightManagement'
// import EmptyLegManagement from '../components/emptyLegManagement/EmptyLegManagement'
// import FlightsManagement from '../components/flightsManagement/FlightsManagement'
// import FinalizeQuotation from '../components/quotationManagement/FinalizeQuotation'
// import SingleConfirmBooking from '../components/bookingManagement/SingleConfirmBooking'
// import Login from '../components/login/Login'
// import PageNotFound from '../components/common/PageNotFound'
// import QuotationManagement from '../components/quotationManagement/QuotationManagement'
// import SingleCoupon from '../components/couponsManagement/SingleCoupon'
// import AddOfferedFlight from '../components/offeredFlightManagement/AddOfferedFlight'
// import AddEmptyLegFlight from '../components/emptyLegManagement/AddEmptyLegFlight'
// import EditAircraft from '../components/fleetManagement/Aircraft/EditAircraft'
// import UserContext from '../context/UserContext'
// import OperatorManagement from '../components/operatorManagement/OperatorManagement'
// import OperatorAircraftDetails from '../components/operatorManagement/OperatorAircraftDetails'
// import MyAircrafts from '../components/operatorPanel/MyAircrafts'
// import QueryManagement from '../components/queryManagement/QueryManagement'
// import Edit from '../components/offeredFlightManagement/Edit'
// import EditEmpty from '../components/emptyLegManagement/EditEmpty'
// import SingleCompleteBooking from '../components/bookingManagement/SingleCompleteBooking'
// import OperatorSingleAircraft from '../components/operatorPanel/OperatorSingleAircraft'
// import EditOperatorAircraft from '../components/operatorManagement/EditOperatorAircraft'
// import SingleOperatorAircraft from '../components/operatorManagement/SingleOperatorAircraft'
// const AllRoutes = ({ isOperator }: any) => {
//     const context = useContext(UserContext);
//     // const uId = context?.authentication?.session?.uId;

//     // commented
//     // useEffect(()=>{
//     //     if(!context.authentication.authenticated && window.location.pathname!="/login"){
//     //         window.location.href="/login"
//     //     }
//     // })
//     return (
//         <Routes>
//             {
//                 isOperator ? (
//                     <Route element={<Layout />}>
//                         <Route path={"/operator-my-aircrafts"} element={<MyAircrafts />} />
//                         <Route path={"/aircraft-details"} element={<OperatorAircraftDetails />} />
//                         <Route path="/operator-my-aircrafts/:aircraftId" element={<OperatorSingleAircraft />} />
//                     </Route>
//                 ) : (
//                         <Route element={<Layout />}>
//                         <Route path={"/users-management"} element={<UserManagement />} />
//                         <Route path={"/fleets-management"} element={<FleetManagement />} />
//                         <Route path={"/fleets/aircraft/add/:fleetId"} element={<AddAircraft />} />
//                         <Route path={"/fleets/aircraft/edit/:aircraftId"} element={<EditAircraft />} />
//                         <Route path="/fleets/aircraft/:aircraftId" element={<SingleAircraft />} />
//                         <Route path={"/airports-management"} element={<AirportManagement />} />
//                         <Route path={"/bookings-management"} element={<BookingManagement />} />
//                         {/* <Route path={"/coupons-management"} element={<CouponsManagement />} /> */}
//                         {/* <Route path={"/coupons/:couponId"} element={<SingleCoupon />} /> */}
//                         <Route path={"/bmj-reward-points"} element={<ManageBMJRewardPoints />} />
//                         <Route path={"/booking-stats"} element={<BookingStats />} />
//                         <Route path={"/offered-flight-management"} element={<OfferedFlightManagement />} />
//                         <Route path={"/offered-flight-management/add"} element={<AddOfferedFlight />} />
//                         <Route path={"/offered-flight-management/edit/:flightId"} element={<Edit />} />
//                         <Route path={"/empty-leg-management"} element={<EmptyLegManagement />} />
//                         <Route path={"/empty-leg-management/add"} element={<AddEmptyLegFlight />} />
//                         <Route path={"/empty-leg-management/edit/:flightId"} element={<EditEmpty />} />
//                         <Route path={"/operator-management"} element={<OperatorManagement />} />
//                         <Route path={"/operator-management/aircraft/edit/:aircraftId"} element={<EditOperatorAircraft />} />
//                         <Route path="/operator-management/aircraft/:aircraftId" element={<SingleOperatorAircraft />} />
//                         <Route path={"/aircraft-details/:aircraftId"} element={<OperatorAircraftDetails />} />
//                         <Route path={"/flights-management"} element={<FlightsManagement />} />
//                         <Route path={"/quotation-management"} element={<QuotationManagement />} />
//                         <Route path={"/finalize-quotation-amount"} element={<FinalizeQuotation />} />
//                         <Route path={"/confirm-booking/:bookingId"} element={<SingleConfirmBooking />} />
//                         <Route path={"/complete-booking/:bookingId"} element={<SingleCompleteBooking />} />
//                         <Route path={"/query-management"} element={<QueryManagement />} />
//                     </Route>
//                 )
//             }

//             {/* {
//                 context.authentication.authenticated
//                 ?
//                 <Route element={<Layout />}>
//                     <Route path={"/"} element={<UserManagement />} />
//                 </Route>
//                 :
//                 <></>
//             } */}
//             <Route path={"/"} element={<Login />} />
//             <Route path={"/login"} element={<Login />} />
//             <Route path={"*"} element={<PageNotFound />} />
//         </Routes>
//     )
// }

// export default AllRoutes

import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../components/common/Layout";
import UserProfile from "../components/userManagement/UserProfile";
import UserManagement from "../components/userManagement/UserManagement";
import FleetManagement from "../components/fleetManagement/FleetManagement";
import AddAircraft from "../components/fleetManagement/Aircraft/AddAircraft";
import SingleAircraft from "../components/fleetManagement/Aircraft/SingleAircraft";
import AirportManagement from "../components/airportManagement/AirportManagement";
import BookingManagement from "../components/bookingManagement/BookingManagement";
import CouponsManagement from "../components/couponsManagement/CouponsManagement";
import CouponDetails from "../components/couponsManagement/CouponDetails";
import ManageBMJRewardPoints from "../components/manageBmjRewardPoints/ManageBMJRewardPoints";
import BookingStats from "../components/bookingStats/BookingStats";
import OfferedFlightManagement from "../components/offeredFlightManagement/OfferedFlightManagement";
import EmptyLegManagement from "../components/emptyLegManagement/EmptyLegManagement";
import FlightsManagement from "../components/flightsManagement/FlightsManagement";
import FinalizeQuotation from "../components/quotationManagement/FinalizeQuotation";
import SingleConfirmBooking from "../components/bookingManagement/SingleConfirmBooking";
import Login from "../components/login/Login";
import PageNotFound from "../components/common/PageNotFound";
import QuotationManagement from "../components/quotationManagement/QuotationManagement";
import SingleCoupon from "../components/couponsManagement/SingleCoupon";
import AddOfferedFlight from "../components/offeredFlightManagement/AddOfferedFlight";
import AddEmptyLegFlight from "../components/emptyLegManagement/AddEmptyLegFlight";
import EditAircraft from "../components/fleetManagement/Aircraft/EditAircraft";
import UserContext from "../context/UserContext";
import OperatorManagement from "../components/operatorManagement/OperatorManagement";
import OperatorAircraftDetails from "../components/operatorManagement/OperatorAircraftDetails";
import MyAircrafts from "../components/operatorPanel/MyAircrafts";
import QueryManagement from "../components/queryManagement/QueryManagement";
import Edit from "../components/offeredFlightManagement/Edit";
import EditEmpty from "../components/emptyLegManagement/EditEmpty";
import SingleCompleteBooking from "../components/bookingManagement/SingleCompleteBooking";
import OperatorSingleAircraft from "../components/operatorPanel/OperatorSingleAircraft";
import EditOperatorAircraft from "../components/operatorManagement/EditOperatorAircraft";
import SingleOperatorAircraft from "../components/operatorManagement/SingleOperatorAircraft";
import { AuthenticatedRoute } from "./AuthenticatedRoute";
import FeedManagement from "../components/feedManagement/FeedManagement";
import { Mails } from "../pages/mails/mails";
import Blogs from "../pages/blogs/BlogsManagement";
import PostBlog from "../pages/blogs/PostBlog";
import BlogsManagement from "../pages/blogs/BlogsManagement";

const AllRoutes = () => {
  const context = useContext(UserContext);
  // const uId = context?.authentication?.session?.uId;

  const isOperator = context?.authentication?.session?.role[0]?.authority &&["ROLE_OPERATOR:EDIT", "ROLE_OPERATOR:VIEW"].includes(context?.authentication?.session.role[0].authority)
  const isBlogger = context?.authentication?.session?.role[0]?.authority &&["ROLE_BLOGGER:EDIT", "ROLE_BLOGGER:VIEW"].includes(context?.authentication?.session.role[0].authority)
  const isMarketer = context?.authentication?.session?.role[0]?.authority &&["ROLE_MARKETER:EDIT", "ROLE_MARKETER:VIEW"].includes(context?.authentication?.session.role[0].authority)
  // commented
  // useEffect(()=>{
  //     if(!context.authentication.authenticated && window.location.pathname!="/login"){
  //         window.location.href="/login"
  //     }
  // })
  return (
    <Routes>
      {isOperator ? (
        <Route element={<Layout />}>
          <Route 
          // path={"/operator-my-aircrafts"} 
          path={"/"} 
        //   element={<MyAircrafts />}
        element={
            <AuthenticatedRoute>
              <MyAircrafts />
            </AuthenticatedRoute>
          }
           />
          <Route
            path={"/aircraft-details"}
            // element={<OperatorAircraftDetails />}
            element={
                <AuthenticatedRoute>
                  <OperatorAircraftDetails />
                </AuthenticatedRoute>
              }
          />
          <Route
            path="/operator-my-aircrafts/:aircraftId"
            // element={<OperatorSingleAircraft />}
            element={
                <AuthenticatedRoute>
                  <OperatorSingleAircraft />
                </AuthenticatedRoute>
              }
          />
        </Route>
      ) : isBlogger ? (
        <Route element={<Layout />}>
          <Route 
          path={"/"} 
        element={
            <AuthenticatedRoute>
              <BlogsManagement />
            </AuthenticatedRoute>
          }
           />
            <Route 
          path={"/blogs-management"} 
        element={
            <AuthenticatedRoute>
              <BlogsManagement />
            </AuthenticatedRoute>
          }
           />
        </Route>
        
      ) :isMarketer ? (
        <Route element={<Layout />}>
          <Route 
          path={"/"} 
        element={
            <AuthenticatedRoute>
              <Mails />
            </AuthenticatedRoute>
          }
           />
          
        </Route>
      )
       : (
        <Route element={<Layout />}>
          {/* <Route path={"/users-management"} element={<UserManagement />} /> */}
          <Route
            // path={"/users-management"}
            path={"/"}
            element={
              <AuthenticatedRoute>
                <UserManagement />
              </AuthenticatedRoute>
            }
          />
          <Route path={"/fleets-management"}  element={
              <AuthenticatedRoute>
                <FleetManagement />
              </AuthenticatedRoute>
            } />
          <Route
            path={"/fleets/aircraft/add/:fleetId"}
            element={
                <AuthenticatedRoute>
                  <AddAircraft />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/fleets/aircraft/edit/:aircraftId"}
            // element={<EditAircraft />}
            element={
                <AuthenticatedRoute>
                  <EditAircraft />
                </AuthenticatedRoute>
              }
          />
          <Route
            path="/fleets/aircraft/:aircraftId"
            // element={<SingleAircraft />}
            element={
                <AuthenticatedRoute>
                  <SingleAircraft />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/airports-management"}
            // element={<AirportManagement />}
            element={
                <AuthenticatedRoute>
                  <AirportManagement />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/feeds-management"}
            // element={<AirportManagement />}
            element={
                <AuthenticatedRoute>
                  <FeedManagement />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/blogs-management"}
            // element={<AirportManagement />}
            element={
                <AuthenticatedRoute>
                  <Blogs />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/blogs-management/post"}
            // element={<AirportManagement />}
            element={
                <AuthenticatedRoute>
                  <PostBlog />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/mails"}
            // element={<AirportManagement />}
            element={
                <AuthenticatedRoute>
                  <Mails />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/bookings-management"}
            // element={<BookingManagement />}
            element={
                <AuthenticatedRoute>
                  <BookingManagement />
                </AuthenticatedRoute>
              }
          />
          {/* <Route path={"/coupons-management"} element={<CouponsManagement />} /> */}
          {/* <Route path={"/coupons/:couponId"} element={<SingleCoupon />} /> */}
          <Route
            path={"/bmj-reward-points"}
            // element={<ManageBMJRewardPoints />}
            element={
                <AuthenticatedRoute>
                  <ManageBMJRewardPoints />
                </AuthenticatedRoute>
              }
          />
          <Route path={"/booking-stats"} 
        //   element={<BookingStats />}
        element={
            <AuthenticatedRoute>
              <BookingStats />
            </AuthenticatedRoute>
          }
           />
          <Route
            path={"/offered-flight-management"}
            // element={<OfferedFlightManagement />}
            element={
                <AuthenticatedRoute>
                  <OfferedFlightManagement />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/offered-flight-management/add"}
            // element={<AddOfferedFlight />}
            element={
                <AuthenticatedRoute>
                  <AddOfferedFlight />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/offered-flight-management/edit/:flightId"}
            // element={<Edit />}
            element={
                <AuthenticatedRoute>
                  <Edit />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/empty-leg-management"}
            // element={<EmptyLegManagement />}
            element={
                <AuthenticatedRoute>
                  <EmptyLegManagement />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/empty-leg-management/add"}
            // element={<AddEmptyLegFlight />}
            element={
                <AuthenticatedRoute>
                  <AddEmptyLegFlight />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/empty-leg-management/edit/:flightId"}
            // element={<EditEmpty />}
            element={
                <AuthenticatedRoute>
                  <EditEmpty />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/operator-management"}
            // element={<OperatorManagement />}
            element={
                <AuthenticatedRoute>
                  <OperatorManagement />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/operator-management/aircraft/edit/:aircraftId"}
            // element={<EditOperatorAircraft />}
            element={
                <AuthenticatedRoute>
                  <EditOperatorAircraft />
                </AuthenticatedRoute>
              }
          />
          <Route
            path="/operator-management/aircraft/:aircraftId"
            // element={<SingleOperatorAircraft />}
            element={
                <AuthenticatedRoute>
                  <SingleOperatorAircraft />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/aircraft-details/:aircraftId"}
            // element={<OperatorAircraftDetails />}
            element={
                <AuthenticatedRoute>
                  <OperatorAircraftDetails />
                </AuthenticatedRoute>
              }
          />
          <Route path={"/flights-management"} 
        //   element={<FlightsManagement />}
        element={
            <AuthenticatedRoute>
              <FlightsManagement />
            </AuthenticatedRoute>
          }
           />
          <Route
            path={"/quotation-management"}
            // element={<QuotationManagement />}
            element={
                <AuthenticatedRoute>
                  <QuotationManagement />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/finalize-quotation-amount"}
            // element={<FinalizeQuotation />}
            element={
                <AuthenticatedRoute>
                  <FinalizeQuotation />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/confirm-booking/:bookingId"}
            // element={<SingleConfirmBooking />}
            element={
                <AuthenticatedRoute>
                  <SingleConfirmBooking />
                </AuthenticatedRoute>
              }
          />
          <Route
            path={"/complete-booking/:bookingId"}
            // element={<SingleCompleteBooking />}
            element={
                <AuthenticatedRoute>
                  <SingleCompleteBooking />
                </AuthenticatedRoute>
              }
          />
          <Route path={"/query-management"} 
        //   element={<QueryManagement />}
        element={
            <AuthenticatedRoute>
              <QueryManagement />
            </AuthenticatedRoute>
          }
           />
        </Route>
      )}

      {/* {
                context.authentication.authenticated
                ?
                <Route element={<Layout />}>
                    <Route path={"/"} element={<UserManagement />} />
                </Route>
                :
                <></>
            } */}
      {/* <Route path={"/"} element={<Login />} /> */}
      <Route path={"/login"} element={<Login />} />
      <Route path={"*"} element={<PageNotFound />} />
    </Routes>
  );
};

export default AllRoutes;
